<template>
  <div class="wrap">
    <div class="pur-top-right-icon">
      <i
        class="iconfont icon-faqishenpi"
        :class="'i-green'"
        v-if="!CANCEL || this.statusObj.UNCHKED"
      />
      <span
        :class="[currentIndex > 1 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.UNCHKED"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
        class="iconfont icon-tijiaoshenqing"
        :class="[currentIndex >= 2 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 2 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.WAITDELIVER"
        class="iconfont icon-xianchangjianding"
        :class="[currentIndex >= 3 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 3 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.WAITDELIVER"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.PARTCHECKED"
        class="iconfont icon-chengyunshang"
        :class="[currentIndex >= 4 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 4 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.PARTCHECKED"
      ></span>
      <i
        v-if="!CANCEL || this.statusObj.PARTCHECKED"
        class="iconfont icon-yanji"
        :class="[currentIndex >= 5 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 5 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.PARTCHECKED"
      ></span>
      <!-- <i
        v-if="!CANCEL || this.statusObj.CUSTSIGIN"
        class="iconfont icon-yanji"
        :class="[currentIndex >= 6 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 6 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.UNCHKED"
      ></span> -->
      <i
        v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
        class="iconfont icon-jieshu"
        :class="[currentIndex >= 6 ? 'i-green' : 'i-gray']"
      />
      
      <i v-if="CANCEL" class="iconfont icon-quxiao i-gray" />
     
    </div>
    <div class="pur-top-right-txt">
      <div v-if="!CANCEL || this.statusObj.UNCHKED">
        <p style="padding-right:4px">发起审批</p>
        <p class="timeTxt" v-if="currentIndex >= 1 ">{{ orderdetail.statusInfo.length>0 ? orderdetail.statusInfo[0].date : '' }}</p>
      </div>
      <div v-if="(!CANCEL || this.statusObj.REVIEWCOMPLETED)">
        <p>产品质量经理审核完成</p>
        <p class="timeTxt" v-if="currentIndex >= 2 ">{{ orderdetail.statusInfo.length>0 ? orderdetail.statusInfo[1].date: '' }}</p>
      </div>
      <div v-if="!CANCEL || this.statusObj.PARTCHECKED">
        <p>拖机委托单审核完成</p>
        <p class="timeTxt" v-if="currentIndex >= 3 ">{{orderdetail.statusInfo.length>0 ? orderdetail.statusInfo[2].date: ''}}</p>
      </div>
      <div v-if="!CANCEL || this.statusObj.CUSTSIGIN">
        <p>承运商指派完成</p>
        <p class="timeTxt" v-if="currentIndex >= 4 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[3].date: '' }}</p>
      </div>

       <div v-if="!CANCEL || this.statusObj.CUSTSIGIN">
        <p>验机待商家确认</p>
        <p class="timeTxt" v-if="currentIndex >= 5 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[4].date: '' }}</p>
      </div>
       <div v-if="!CANCEL || this.statusObj.CUSTSIGIN">
        <p>结束</p>
        <p class="timeTxt" v-if="currentIndex >= 6 ">{{orderdetail.statusInfo.length>0 ?orderdetail.statusInfo[5].date: '' }}</p>
      </div>

      <div v-if="CANCEL">
        <p>取消</p>
        <p>{{ this.statusObj.CANCEL }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("zh-cn");
export default {
  name: "Step",
  props: {
    orderdetail: {
      required: true,
      type: Object
    }
  },
  watch: {
    "orderdetail": {
      handler: function() {
        this.statusObj = {};
        if (this.orderdetail && this.orderdetail.statusInfo) {
          this.orderdetail.statusInfo.forEach(item => {
            this.statusObj[item.status] = item.date;
          });
        }
        if (this.statusObj.CANCEL) {
          this.CANCEL = true;
        } else {
          this.CANCEL = false;
        }
      },
      immediate: true
    }
  },
  computed: {
    currentIndex: function() {
      let statusA = this.orderdetail.statusInfo.some(item=>item.code == 'END')
      let step = 1;
      if (this.orderdetail && this.orderdetail.statusInfo) {
        if(this.orderdetail.statusInfo.length == 1) {
          step = 1
        }
        if(this.orderdetail.statusInfo.length == 2) {
          step = 2
        }
        if(this.orderdetail.statusInfo.length == 3) {
          step = 3
        }
        if(this.orderdetail.statusInfo.length == 4) {
          step = 4
        }
        if(this.orderdetail.statusInfo.length == 5) {
          step = 5
        }
        if(this.orderdetail.statusInfo.length == 6) {
          step = 6
        }
         if(this.orderdetail.statusInfo.length == 7) {
          step = 7
        }
        if(this.orderdetail.statusInfo.length == 8) {
          step = 8
        }
        if(this.orderdetail.statusInfo.length == 9) {
          step = 9
        }
        if(this.orderdetail.statusInfo.length == 10) {
          step = 10
        }
        return step;
      } else {
        return step;
      }
    }
  },
  data() {
    return {
      statusObj: {},
      infoMessage:[],
      CANCEL: false
    };
  },
  methods:{
    formatDate(date){
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style scoped lang="less">
.wrap {
  width: 930px;
  height: 139px;
  background: #fff;

  .pur-top-right-icon {
    text-align: left;
    padding-left: 70px;

    .iconfont {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 21px;
      position: relative;
      top: 8px;
      text-align: center;
    }

    .i-green {
      color: #00aaa6;
      border: 1.5px solid #00aaa6;
    }

    .i-gray {
      color: #aaa;
      border: 1.5px solid #aaa;
    }

    img {
      width: 36px;
      height: 36px;
    }

    span {
      width: 116px;
      margin: 18px 7px 0;
    }

    .green {
      border-bottom: 1px dashed #00aaa6;
    }

    .gray {
      border-bottom: 1px dashed #cccccc;
    }
  }

  .pur-top-right-txt {
    margin: 12px 0 0 28px;
    // :nth-child(1){
    // }
    :nth-child(6) {
      margin-right: 0px;
    }

    div {
      float: left;
      width: 155px;
      margin: 0 0px 0 13px;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #262626;
        line-height: 12px;
        margin-bottom: 6px;
        margin-top: 12px;
      }
       .timeTxt {
        margin-top: -2px;
      }
    }
    div:first-child {
      float: left;
      width: 124px;
      margin: 0 0px 0 0;
      margin-left: 0;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #262626;
        line-height: 12px;
        margin-bottom: 6px;
        // margin-top: 12px;
      }
    }
  }
}
</style>
